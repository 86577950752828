import Vue from 'vue';
import Component from 'vue-class-component';
import STATUS_UTIL from '@/utils/stats.util';
import dayjs from 'dayjs';

@Component({
	filters: {
		percentValue2(value: any): string {
			return STATUS_UTIL.percent(value, 2);
		},

		percentValue1(value: any): string {
			return STATUS_UTIL.percent(value, 1);
		},

		percentValue0(value: any): string {
			return STATUS_UTIL.percent(value, 0);
		},

		timeAsMinutes(value: any): string {
			return STATUS_UTIL.toMinutes(value);
		},
	},

})
export default class StatsTableMixin extends Vue {

	roundValue2(value: any, key: any) {
		return this.roundValue(value, key, 2);
	}

	roundValue1(value: any, key: any) {
		return this.roundValue(value, key, 1);
	}

	roundValue0(value: any, key: any) {
		return this.roundValue(value, key, 0);
	}

	roundValue(value: any, key: any, digits: number) {
		return STATUS_UTIL.round(value, digits);
	}

	bigNumber(value: any) {
		return STATUS_UTIL.numberBig(value);
	}

	timeOnIce(value: any) {
		return value ? this.muteNulls(STATUS_UTIL.round(value / 60, 0)) : '';
	}

	dateFormatShort(value: any, key: any) {
		return value ? this.dateFormat(value, key, 'DD.MM.') : '';
	}

	dateFormat(value: any, key: any, format: string) {
		return value ? dayjs(value).format(format) : '';
	}

	muteNulls(value: any) {
		if (value === null) {
			value = '';
		}
		return !value ? `<span class="hf-text-muted">${value}</span>` : value;
	}
}
