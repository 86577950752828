import S from 'string';
import Vue from 'vue';
import STATUS_UTIL from '@/utils/stats.util';
import dayjs from 'dayjs';

export const EMPTY = '';

export const CELL_CLASSES = {
	muteZero: function (params: any) {
		const val = params.value;
		if (!val || val === 0 || val === '0' || val === '0.0' || val === '0.00') {
			return 'ag-numeric-cell hf-text-muted';
		} else {
			return 'ag-numeric-cell';
		}
	},
	muteZeroPlusMinus: function (params: any) {
		const val = params.value;
		if (!val) {
			return 'ag-numeric-cell hf-text-muted';
		} else {
			return 'ag-numeric-cell';
		}
	}
};

export const COMPARATORS = {
	/** Sort such that nulls are first when sorting ascending (and last when descending). */
	numberNullsFirstAsc: function (a: any, b: any) {
		// FIXME detect sort order to put 'null last' regardless of sort-order
		if (a === undefined || a === null) {
			return -1;
		}
		if (b === undefined || b === null) {
			return 1;
		}
		return a - b;
	},

	/** Special sort for plus/minus such that unknown values are treated as 0 */
	sortPlusMinus: function (a: any, b: any) {
		let valA = a;
		if (a === undefined || a === null) {
			valA = 0;
		}
		let valB = b;
		if (b === undefined || b === null) {
			valB = 0;
		}
		return valA - valB;
	},
};

export const COLUMN_FORMATTERS = {
	formatSeason: function (params: any) {
		const value = params.value;
		if (!value) {
			return '';
		}
		return `${S(value).left(2).s}/${S(value).right(2).s}`;
	},
	formatUpperCase: function (params: any) {
		if (!params.value) {
			return '';
		}
		return params.value.toUpperCase();
	},
	percentValue2: function (params: any) {
		if (!params.value) {
			return '-';
		}
		return STATUS_UTIL.percent(params.value, 2);
	},
	percentValue1: function (params: any) {
		if (!params.value) {
			return '-';
		}
		return STATUS_UTIL.percent(params.value, 1);
	},
	percentValue0: function (params: any) {
		if (!params.value) {
			return '-';
		}
		return STATUS_UTIL.percent(params.value, 0);
	},
	roundValue2: function (params: any) {
		return STATUS_UTIL.round(params.value, 2);
	},
	roundValue1: function (params: any) {
		return STATUS_UTIL.round(params.value, 1);
	},
	roundValue0: function (params: any) {
		return STATUS_UTIL.round(params.value, 0);
	},
	numberBig: function (params: any) {
		const val = params.value;
		if (val === undefined || val === null) {
			return '-';
		}
		return STATUS_UTIL.numberBig(params.value);
	},
	numberBoolean: function (params: any) {
		const val = params.value;
		if (typeof val === 'boolean') {
			return val ? '✓' : '';
		}
		if (val === undefined || val === null) {
			return '-';
		}
		return val;
	},
	numberNull: function (params: any) {
		const val = params.value;
		if (val === undefined || val === null) {
			return '-';
		}
		return val;
	},
	timeAsMinutes: function (params: any) {
		return STATUS_UTIL.toMinutes(params.value);
	},
	timeOnIce: function (params: any) {
		const val = params.value;
		if (val === 0 && params.data.hasOwnProperty('spare') && params.data.spare) {
			return '-';
		}
		if (!val) {
			return '';
		}
		return STATUS_UTIL.toMinutes(params.value);
	},
	plusMinus: function (params: any) {
		const val = params.value;
		if (val === undefined || val === null || isNaN(val)) {
			return '-';
		}
		if (val > 0) {
			return `+${val}`;
		}
		if (val === 0) {
			return '±0';
		}
		return val;
	},
	formatSUN: function (params: any) {
		const item = params.data;
		if (item.s === 1) {
			return 'S';
		}
		if (item.n === 1) {
			return 'N';
		}
		if (item.u === 1) {
			return 'U';
		}
		return '';
	},
	formatWonLoss: function (params: any) {
		const item = params.data;
		if (item.won) {
			return 'S';
		} else if (item.loss) {
			return 'N';
		} else if (item.tie) {
			return 'U';
		} else {
			return '';
		}
	},
	formatOT: function (params: any) {
		const item = params.data;
		return item.otS + item.otN >= 1
			? (item.otS === 1 ? 'V+' : 'V-')
			: (item.penS + item.penN >= 1 ? (item.penS === 1 ? 'P+' : 'P-') : '');
	},
	formatResult: function (params: any) {
		const item = params.data;
		const overtime = item.otS + item.otN >= 1
			? (item.otS === 1 ? 'nV+' : 'nV-')
			: (item.otPenN + item.penS + item.otPenN + item.penN >= 1 ? (item.otPenS + item.penS >= 1 ? 'nP+' : 'nP-') : '');
		return `${item.gteam}:${item.gopp} ${overtime}`;
	},
	dateFormatVeryShort: function (params: any) {
		if (!params.value) {
			return '';
		}
		return dayjs(params.value).format('D.M.');
	},
	dateFormatVeryShortWeekday: function (params: any) {
		if (!params.value) {
			return '';
		}
		return dayjs(params.value).format('dd - D.M.');
	},
	dateFormatShort: function (params: any) {
		if (!params.value) {
			return '';
		}
		return dayjs(params.value).format('DD.MM.');
	},
	dateFormatLong: function (params: any) {
		if (!params.value) {
			return '';
		}
		return dayjs(params.value).format('DD.MM.YYYY');
	},
	oppField: function (params: any) {
		const item = params.data;
		const field = params.colDef.field.replace('Both', '');
		const fieldOpp = field + 'Opp';
		if (isNaN(item[field]) || isNaN(item[fieldOpp])) {
			Vue.$log.warn('field not found for column', item, field);
			return '';
		} else {
			return `${item[field]}•${item[fieldOpp]}`;
		}
	}
};

export const COLUMN_TYPES: any = {
	'number': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.numberNull,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'numberBig': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.numberBig,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'numberBoolean': {
		valueFormatter: COLUMN_FORMATTERS.numberBoolean,
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
	},
	'numberBoth': {
		// TODO [stats] numberBoth
		valueFormatter: COLUMN_FORMATTERS.oppField,
		cellClass: 'has-text-centered',
		headerTooltip: 'Team • Gegner'
	},
	'decimal': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.roundValue1,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'decimal0': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.roundValue0,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'decimal2': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.roundValue2,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'plusMinus': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZeroPlusMinus,
		valueFormatter: COLUMN_FORMATTERS.plusMinus,
		comparator: COMPARATORS.sortPlusMinus,
	},
	'percent0': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.percentValue0,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'percent1': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.percentValue1,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'percent2': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.percentValue2,
		comparator: COMPARATORS.numberNullsFirstAsc,
	},
	'time': {
		type: 'numericColumn',
		cellClass: CELL_CLASSES.muteZero,
		valueFormatter: COLUMN_FORMATTERS.timeAsMinutes
	},
	'team': {
		headerName: EMPTY,
		cellRendererFramework: 'GridTeamLogo',
		cellClass: 'hf-grid-club',
	},
	'game': {
		headerName: '🏒',
		headerTooltip: 'Zum Spiel',
		cellRendererFramework: 'GridGameLink',
		suppressSizeToFit: true,
		unsortable: true,
	},
	'homeAway': {
		headerName: 'HA',
		valueFormatter: COLUMN_FORMATTERS.formatUpperCase,
	},
	'position': {
		headerName: 'Pos',
		valueFormatter: COLUMN_FORMATTERS.formatUpperCase,
		suppressSizeToFit: true,
		unsortable: true,
		centered: true,
	},
	'season': {
		headerName: '🗓',
		headerTooltip: 'Saison',
		cellRendererFramework: 'GridSeasonLink',
		suppressSizeToFit: true,
		width: 75,
	},
	'gameType': {
		headerName: 'Typ',
		valueFormatter: COLUMN_FORMATTERS.formatUpperCase,
		suppressSizeToFit: true,
		width: 50,
	},
	'league': {
		headerName: 'Liga',
		valueFormatter: COLUMN_FORMATTERS.formatUpperCase,
		suppressSizeToFit: true,
		width: 50,
	},
	'country': {
		valueGetter: (p: any) => {
			// TODO [improvement] translate country
			// TODO [improvement] migrate to bulma-table
			return {iso: p.data[p.colDef.field], name: p.data[p.colDef.field].toUpperCase()};
		},
		cellRendererFramework: 'GridCountryFlag',
		comparator: (c1: any, c2: any) => {
			return c1.name.localeCompare(c2.name);
		},
	},
	'dateShort': {
		headerName: '📅',
		valueFormatter: COLUMN_FORMATTERS.dateFormatShort,
		suppressSizeToFit: true,
	},
	'player': {
		headerName: 'Spieler',
		cellRendererFramework: 'GridPlayerLink',
		suppressSizeToFit: true,
		width: 110,
		cellClass: 'hf-truncated-135 has-text-link',
	},
	'sun': {
		headerName: 'S/N',
		headerTooltip: 'Sieg / Niederlage',
		valueFormatter: COLUMN_FORMATTERS.formatWonLoss,
	},

};

export function opponent(definition: any, suffix?: string) {
	suffix = suffix || 'des Gegners';
	definition = {
		...definition,
		headerName: `[${definition.headerName}]`,
	};
	if (definition.headerTooltip) {
		definition = {
			...definition,
			headerTooltip: `${definition.headerTooltip} ${suffix}`,
		};
	}
	return definition;
}
