

















import {Component, Prop, Vue} from 'vue-property-decorator';
import COLUMNS from '@/services/columns/column.service';
import STATUS_UTIL from '@/utils/stats.util';

@Component({
  components: {},
})
export default class GameStats extends Vue {
  @Prop(Object) gameData!: any;

  column(field: string) {
    if (!COLUMNS.gamestats[field]) {
      return field.toUpperCase();
    }
    return COLUMNS.gamestats[field].headerNameLong || COLUMNS.gamestats[field].headerName;
  }

  get hasData() {
    return this.gameData.gameStats || this.gameData.shotStats;
  }

  get gameStatsFields() {
    return ['min2', 'min5', 'min10', 'disc', 'pim', 'ps', 'plmi', 'wg', 'en'];
  }

  get shotStatsFields() {
    return ['shotsH1', 'shotsH2', 'shotsH3', 'shotsHot', 'shotsHt'];
  }

  get ppStatsFields() {
    return ['shGoalsFor', 'ppGoalsFor',  'ppOpp', 'ppTime'];
  }

  get columns() {
    return ['homeValue', 'awayValue', 'field'];
  }

  get values() {
    let gameStats: any[] = [];
    if (this.gameData.gameStats) {
      gameStats = this.gameStatsFields.map((field: any) => {
        return {
          homeValue: this.gameData.gameStats[`${field}H`],
          awayValue: this.gameData.gameStats[`${field}A`],
          fieldId: field,
          fieldName: this.column(field),
        };
      }).filter(d => d.homeValue || d.awayValue);
      if (this.gameData.shotStats) {
        const sogPctH = (this.gameData.gameStats.homeGoals - this.gameData.gameStats.penS) / this.gameData.shotStats['shotsHt'];
        const sogPctA = (this.gameData.gameStats.awayGoals - this.gameData.gameStats.penN) / this.gameData.shotStats['shotsAt'];
        gameStats = [...gameStats, {
          homeValue: STATUS_UTIL.percent(sogPctH, 1),
          awayValue: STATUS_UTIL.percent(sogPctA, 1),
          fieldId: 'sogPct',
          fieldName: 'Schusseff. %',
        }];
      }
    }
    let shotStats: any[] = [];
    if (this.gameData.shotStats) {
      shotStats = this.shotStatsFields.map((field: any) => {
        return {
          homeValue: this.gameData.shotStats[`${field}`],
          awayValue: this.gameData.shotStats[`${field}`.replace('H', 'A')],
          fieldId: field,
          fieldName: this.column(field),
        };
      }).filter(d => d.fieldId === 'shotsHot' && (d.homeValue || d.awayValue));
    }
    let ppStats: any[] = [];
    if (this.gameData.powerplayStats) {
      ppStats = this.ppStatsFields.map((field: any) => {
        return {
          homeValue: this.gameData.powerplayStats[`${field}H`],
          awayValue: this.gameData.powerplayStats[`${field}A`],
          fieldId: field,
          fieldName: this.column(field),
        };
      });
      ppStats.filter(v => v.fieldId === 'ppTime').forEach(v => {
        v.homeValue = STATUS_UTIL.toMinutes(v.homeValue);
        v.awayValue = STATUS_UTIL.toMinutes(v.awayValue);
      });
      const ppQuoteH = this.gameData.powerplayStats['ppGoalsForH'] / this.gameData.powerplayStats['ppOppH'];
      const ppQuoteA = this.gameData.powerplayStats['ppGoalsForA'] / this.gameData.powerplayStats['ppOppA'];
      ppStats = [...ppStats, {
        homeValue: STATUS_UTIL.percent(ppQuoteH, 1),
        awayValue: STATUS_UTIL.percent(ppQuoteA, 1),
        fieldId: 'ppQuote',
        fieldName: 'PP-Quote %',
      }];
      const ppTimePerGoalH = this.gameData.powerplayStats['ppTimeH'] / this.gameData.powerplayStats['ppGoalsForH'];
      const ppTimePerGoalA = this.gameData.powerplayStats['ppTimeA'] / this.gameData.powerplayStats['ppGoalsForA'];
      ppStats = [...ppStats, {
        homeValue: isFinite(ppTimePerGoalH) ? STATUS_UTIL.toMinutes(ppTimePerGoalH) : '-',
        awayValue: isFinite(ppTimePerGoalA) ? STATUS_UTIL.toMinutes(ppTimePerGoalA) : '-',
        fieldId: 'ppTimePerGoal',
        fieldName: 'PP-Zeit pro Tor',
      }];
    }

    return [...shotStats, ...gameStats, ...ppStats];
  }

}
