































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameDetail, roundInfo} from '@/model/game/game.model';
import StringMixin from '@/mixins/string-filters.mixin';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import dayjs from 'dayjs';
import GameHeaderResult from '@/components/game/GameHeaderResult.vue';

@Component({
  components: {GameHeaderResult, HfTeamLogo},
  mixins: [StringMixin],
})
export default class GameHeader extends Vue {
  @Prop(Object) game!: GameDetail;

  get subtitle(): string {
    const subtitle: string[] = [];
    if (this.game.info.leagueId) {
      subtitle.push(this.$t(`leagueIdShort.${this.game.info.leagueId}`));
    }
    if (this.game.info.timestamp) {
      const date = dayjs(this.game.info.timestamp * 1000);
      if (date.isValid()) {
        subtitle.push(date.format('dddd'));
        subtitle.push(date.format('DD.MM.YYYY'));
      }
    }
    if (this.game.info.time) {
      subtitle.push(this.game.info.time + ' Uhr');
    }
    if (this.game.info.round) {
      subtitle.push(roundInfo(this, this.game.info));
    }
    if (this.game.info.placeText) {
      subtitle.push(this.game.info.placeText);
    }
    if (this.game.data && this.game.data.attendance) {
      subtitle.push(`${this.game.data.attendance} Zuschauer`);
    }
    return subtitle.map(s => s.replace(' ', '&nbsp;')).join(' • ');
  }
}
