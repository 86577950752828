








































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameEvent, GameEventType, GameInfo, GkIn, GkOut, Goal, Penalty, Period} from '@/model/game/game.model';
import {FilterValue, GameContentType} from '@/model/site/components.model';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';

const FILTERS: FilterValue<GameEventType>[] = [
  {value: GameEventType.ALL, name: 'Alle', iconName: 'undo', iconPack: 'fa'},
  {value: GameEventType.GOAL, name: 'Tore', iconName: 'hockey-puck', iconPack: 'fa'},
  {value: GameEventType.PENALTY, name: 'Strafen', iconName: 'bell', iconPack: 'fa'},
  {value: GameEventType.GOALKEEPER, name: 'Torhüter', iconName: 'sign-in-alt', iconPack: 'fa'},
];

@Component({
  components: {HfTeamLogo},
})
export default class GameTelegramm extends Vue {
  @Prop(Object) data!: any;
  @Prop(Object) info!: GameInfo;
  eventType: GameEventType = GameEventType.ALL;
  filtered = false;

  get id() {
    return GameContentType.TELEGRAMM;
  }

  get filterValues() {
    return FILTERS;
  }

  iconHomeAway(event: any): string {
    if (this.isHome(event)) {
      return 'home';
    }
    if (this.isAway(event)) {
      return 'bus';
    }
    return '';
  }

  isHome(event: any): boolean {
    if (!event || !event.data || !event.data.ha) {
      return false;
    }
    return event.data.ha === 'H';
  }

  isAway(event: any): boolean {
    if (!event || !event.data || !event.data.ha) {
      return false;
    }
    return event.data.ha === 'A';
  }

  isFiltered(eventType: GameEventType) {
    return this.eventType === eventType;
  }

  filter(eventType: GameEventType) {
    this.eventType = eventType;
  }

  isMuted(event: GameEvent): boolean {
    if (this.eventType === GameEventType.ALL) {
      return false;
    }
    return event.eventType !== this.eventType;
  }

  isPeriod(event: GameEvent): boolean {
    return event.eventType === GameEventType.PERIOD;
  }

  get events(): GameEvent[] {
    if (!this.data || !this.data.goals || !this.data.penaltys) {
      return [];
    }
    let events: any[] = [];
    if (!this.filtered || this.eventType === GameEventType.ALL || this.eventType === GameEventType.GOAL) {
      events = [...events, ...this.data.goals.map((data: any) => new Goal(data, this.info))];
    }
    if (!this.filtered || this.eventType === GameEventType.ALL || this.eventType === GameEventType.PENALTY) {
      events = [...events, ...this.data.penaltys.map((data: any) => new Penalty(data, this.info))];
    }
    if (!this.filtered || this.eventType === GameEventType.ALL || this.eventType === GameEventType.GOALKEEPER) {
      events = [...events, ...this.data.goalies.map((data: any) => new GkIn(data, this.info))];
      events = [...events, ...this.data.goalies.map((data: any) => new GkOut(data, this.info))];
    }
    events = [...events, new Period(1, '1. Drittel'), new Period(2, '2. Drittel'), new Period(3, '3. Drittel')];
    if (this.data.overtime) {
      events.push(new Period(4, 'Verlängerung'));
    }
    return events.sort((e1: GameEvent, e2: GameEvent) => {
      const cmp = e1.timeDisplay.localeCompare(e2.timeDisplay);
      if (cmp === 0) {
        if (e1 instanceof GkIn) {
          return 1;
        }
        if (e2 instanceof GkIn) {
          return -1;
        }
      }
      return cmp;
    });
  }
}
