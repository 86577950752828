






































































import {Component, Inject, Prop, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {
  CONTEXT,
  FOCUS_DATE,
  GAME_CONFIG,
  SELECT_GROUP_ID,
  SELECT_LEAGUE_ID,
  SET_FOCUS_DATE
} from '@/store/context.store';
import {Context, GameMode} from '@/model/site/context.model';
import {Box, GameContentType, HfPage, ImageDescription} from '@/model/site/components.model';
import EventGames from '@/components/event/EventGames.vue';
import EventRanking from '@/components/event/EventRanking.vue';
import {GameDetail, GameInfo, IndexedGames} from '@/model/game/game.model';
import {PlayoffSerieTO} from '@/model/ranking.model';
import {GameDetailService} from '@/services/game-detail.service';
import {GAMES, GAMES_AT_DATE, gamesByDate} from '@/store/data/getters';
import {nil} from '@/utils/common.util';
import GamePictures from '@/components/game/GamePictures.vue';
import GameTelegramm from '@/components/game/GameTelegramm.vue';
import dayjs from 'dayjs';
import GameAssignmentStats from '@/components/game/GameAssignmentStats.vue';
import {GameService} from '@/services/game.service';
import GameList from '@/components/game/GameList.vue';
import {LOAD_GAMES_AT_DATE} from '@/store/data/actions';
import GameHeader from '@/components/game/GameHeader.vue';
import GameGoalkeeperStats from '@/components/game/GameGoalkeeperStats.vue';
import Loading from 'vue-loading-overlay';
import PlayoffSerie from '@/components/event/PlayoffSerie.vue';
import {PlayoffService} from '@/services/playoff.service';
import {getGroupId, isExhibition, isPlayoff} from '@/model/core/core-data.model';
import MatchdayCalendar from '@/components/games/MatchdayCalendar.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import ShareThis from '@/components/shared/social/ShareThis.vue';
import MatchdayLinks from '@/components/games/MatchdayLinks.vue';
import ArticleDisplay from '@/components/ArticleDisplay.vue';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import GameStats from '@/components/game/GameStats.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import MobileMixin from '@/mixins/mobile.mixin';
import GoldbachOutstream from '@/components/ads/GoldbachOutstream.vue';

@Component({
    components: {
      GoldbachOutstream,
      PanelBox,
      HfAdBox,
      SideBoxes,
      SportLayout,
      ArticleDisplay,
      MatchdayLinks,
      ShareThis,
      GameGoalkeeperStats,
      GameHeader,
      GameAssignmentStats,
      GameTelegramm,
      GamePictures,
      Loading,
    },
  mixins: [MetaMixin, RouteMixin, MobileMixin],
    metaInfo() {
      let metaInfo = this.metaInfo;
      metaInfo = {titleTemplate: null, ...metaInfo};
      return metaInfo;
    },
  }
)
export default class GamePage extends Vue implements HfPage {

  @Inject('gameDetailService') gameDetailService!: GameDetailService;
  @Inject('gameService') gameService!: GameService;
  @Inject('playoffService') playoffService!: PlayoffService;

  @Prop(String) gameId!: string;
  @Prop(String) contentType!: GameContentType;

  @Getter(CONTEXT) context!: Context;
  @Getter(GAME_CONFIG) gameConfig!: any;
  @Getter(GAMES) games!: any;
  @Getter(GAMES_AT_DATE) gamesAtDate!: any;
  @Getter(FOCUS_DATE) focusDate!: Date;

  static readonly PICTURES_TAB_IDX = 2;

  gameDetail?: GameDetail = nil();
  playoffSerie?: PlayoffSerieTO = nil();

  homeGames: GameInfo[] = [];
  awayGames: GameInfo[] = [];


  get headTitle() {
    if (!this.gameDetail || !this.gameDetail.info) {
      return undefined;
    }
    const info = this.gameDetail.info;
    const league = this.$t(`leagueIdShort.${info.leagueId}`);
    const text = `${league} | ${dayjs(info.date).format('DD.MM.YY')} | ${info.homeTeamNameShort} - ${info.awayTeamNameShort} ${info.result}`;
    if (info.hasArticle && info.articleTitle) {
      return `${text} | ${info.articleTitle}`;
    }

    return text;
  }

  get headDescription() {
    if (!this.gameDetail || !this.gameDetail.info) {
      return undefined;
    }
    const info = this.gameDetail.info;
    if (info.hasArticle && this.gameDetail.article) {
      return this.gameDetail.article.lead;
    }
    if (info.hasData && this.gameDetail.data) {
      const noGoals = '(keine Tore)';
      return `${info.homeTeamName}: ${info.scorersHome || noGoals} | ${info.awayTeamName}: ${info.scorersAway || noGoals}`;
    }
    return '';
  }

  get canonicalUrl() {
    return this.urlFromCurrentPath;
  }

  get imageDesc(): ImageDescription | undefined {
    if (!this.gameDetail || !this.gameDetail.pictures || !this.gameDetail.pictures.length || !this.gameDetail.pictures[0]) {
      return undefined;
    }
    const picture = this.gameDetail.pictures[0];
    return {
      src: picture.fullUrl,
      alt: picture.description
    };
  }

  get activeTab(): number {
    switch (this.contentType) {
      case GameContentType.BERICHT:
        return 0;
      case GameContentType.TELEGRAMM:
        return 1;
      case GameContentType.BILDER:
        return GamePage.PICTURES_TAB_IDX;
      default:
        Vue.$log.warn('Illegal GameContentType', this.contentType);
        this.$router.push(`/404?path=${this.$route.path}`);
        return -1;
    }
  }

  set activeTab(idx: number) {
    // empty
  }

  onTabChange(index: number) {
    if (index === GamePage.PICTURES_TAB_IDX) {
      const pictures: any = this.$refs['pictures'];
      if (pictures) {
        pictures.forceRefresh();
      } else {
        Vue.$log.warn('Could not refresh pictures, wrong "ref"?');
      }
    }
  }

  get hasArticle(): boolean {
    return this.gameDetail && this.gameDetail.info && this.gameDetail.info.hasArticle || false;
  }

  get hasData(): boolean {
    return this.gameDetail && this.gameDetail.info && this.gameDetail.info.hasData && this.gameDetail.data || false;
  }

  get hasPictures(): boolean {
    return this.gameDetail && this.gameDetail.info && this.gameDetail.info.hasPictures || false;
  }

  get pictures() {
    return this.gameDetail ? this.gameDetail.pictures : [];
  }

  get date(): Date | undefined {
    if (this.gameDetail && this.gameDetail.info.timestamp) {
      const date = dayjs(this.gameDetail.info.timestamp * 1000);
      if (date.isValid()) {
        return date.toDate();
      }
      Vue.$log.warn(`illegaal date ${date} on GamePage`);
    }
    return undefined;
  }

  get maxPicturesSide(): number {
    return this.gameConfig ? this.gameConfig.maxPicturesSide : 0;
  }

  get maxPicturesMain(): number {
    return this.gameConfig ? this.gameConfig.maxPicturesMain : 0;
  }

  get leagueGamesAtDate(): IndexedGames {
    return gamesByDate(this.gamesAtDate, this.context.leagueId);
  }

  get boxes(): Box[] {
    const dateGames: Box = {
      title: dayjs(this.date).format('D. MMMM YYYY'),
      component: EventGames,
      props: {
        mode: GameMode.DATE,
        gamesAtDate: this.leagueGamesAtDate,
        showMatchdayLink: true
      },
    };
    const ad: Box = {
      component: HfAdBox,
      props: {formatKey: 'rectangle', targets: ['desktop']},
    };
    const calendar: Box = {
      title: 'Datum',
      component: MatchdayCalendar,
    };
    let boxes = [dateGames, calendar];

    if (this.gameDetail) {
      if (this.gameDetail.data) {
        const gameStats: Box = {
          title: 'Spiel-Statistiken',
          component: GameStats,
          props: {
            gameData: this.gameDetail.data,
          }
        };
        boxes = [...boxes, gameStats];
      }
      const homeGames: Box = {
        title: this.gameDetail.info.homeTeamName,
        component: GameList,
        props: {
          games: this.homeGames,
          mode: GameMode.SAME_TEAM,
          teamId: this.gameDetail.info.homeTeamId,
          waitingId: 'homeGames',
        },
      };
      const awayGames: Box = {
        title: this.gameDetail.info.awayTeamName,
        component: GameList,
        props: {
          games: this.awayGames,
          mode: GameMode.SAME_TEAM,
          teamId: this.gameDetail.info.awayTeamId,
          waitingId: 'awayGames',
        },
      };
      boxes = [...boxes, homeGames, awayGames, ad];
      if (this.playoffSerie) {
        const playoff: Box = {
          title: 'Playoff',
          component: PlayoffSerie,
          props: {
            playoffSerie: this.playoffSerie,
          },
        };
        boxes = [playoff, ...boxes];
      } else if (!isPlayoff(this.gameDetail.info) && !isExhibition(this.gameDetail.info)) {
        const ranking: Box = {
          component: EventRanking,
          props: {date: this.date, force: true},
        };
        boxes = [ranking, ...boxes];
      }

    }

    return boxes;
  }

  created() {
    this.loadGameById(this.gameId);
  }

  @Watch('gameId')
  onGameIdChange(gameId: string) {
    this.loadGameById(gameId);
  }

  loadGameById(gameId: string) {
    this.$store.commit('currentGameId', gameId);
    this.$wait.start('currentGameId');
    if (this.games.hasOwnProperty(gameId)) {
      this.loadGameByInfo(this.games[gameId]);
    } else {
      this.gameDetailService.info(gameId)
        .then((info: GameInfo) => {
          if (info) {
            this.loadGameByInfo(info);
          } else {
            Promise.reject(`Keine Informationen zum Spiel "${gameId}" vorhanden`);
          }
        })
        .catch((error: any) => {
          this.$router.push(`/404?path=${this.$route.fullPath}&message=${error}`);
        })
        .finally(() => this.$wait.end('currentGameId'));
    }
  }

  loadGameByInfo(gameInfo: GameInfo) {
    this.$wait.start('currentGameId');
    if (isPlayoff(gameInfo)) {
      this.loadPlayoffSerie(gameInfo.gameId);
    }
    this.gameDetailService.detail(gameInfo).then((detail: GameDetail) => {
      this.gameDetail = detail;
      this.$store.commit(SELECT_LEAGUE_ID, this.gameDetail.info.leagueId);
      this.$store.commit(SELECT_GROUP_ID, getGroupId(this.gameDetail.info));
      const date = this.date;
      if (date && !dayjs(date).isSame(dayjs(this.focusDate))) {
        this.$store.commit(SET_FOCUS_DATE, date);
      }
    }).finally(() => this.$wait.end('currentGameId'));
    this.loadMoreGames(gameInfo);
    this.$store.dispatch(LOAD_GAMES_AT_DATE, new Date(gameInfo.timestamp * 1000));
  }

  loadMoreGames(gameInfo: GameInfo) {
    const cnt = this.context.configuration.game ? this.context.configuration.game.lastNextGamesCnt || 0 : 0;
    if (cnt === 0) {
      return;
    }
    this.$wait.start('homeGames');
    this.$wait.start('awayGames');
    this.gameService.lastNextByTeam(gameInfo.homeTeamId, cnt).then((data: any) => this.homeGames = data).finally(() => this.$wait.end('homeGames'));
    this.gameService.lastNextByTeam(gameInfo.awayTeamId, cnt).then((data: any) => this.awayGames = data).finally(() => this.$wait.end('awayGames'));

    if (isPlayoff(gameInfo)) {
      this.loadPlayoffSerie(gameInfo.gameId);
    }
  }

  loadPlayoffSerie(gameId: string) {
    this.playoffService.playoffSerieByGameId(gameId)
      .then((serie: PlayoffSerieTO) => this.playoffSerie = serie)
      .catch((e: any) => this.$log.error(`Error loading Playoff-serie for '${gameId}'`, e));
  }

}
