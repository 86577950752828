











































































import {Component, Prop} from 'vue-property-decorator';
import {GameContentType} from '@/model/site/components.model';
import StatsTableMixin from '@/mixins/stats-table.mixin';
import {Vue} from 'vue-property-decorator';
import {playerStatsLink} from '@/router';

@Component({
  components: {},
  mixins: [StatsTableMixin],
})
export default class GameGoalkeeperStats extends Vue {
  @Prop(Array) data!: any[];

  get id() {
    return GameContentType.STATS + '_gk';
  }

  get safeData() {
    this.data.forEach(d => {
      if (!d.stats) {
        d.stats = {};
      }
    });
    return this.data;
  }

  winLoss(stats: any) {
    if (stats.won === 1) {
      return 'W';
    } else if (stats.loss === 1) {
      return 'L';
    } else if (stats.tie === 1) {
      return 'T';
    } else {
      return '';
    }
  }

  muted(value: any) {
    // note that using `v-html ... muteNulls(.)` will cause column headers to dissapear on update
    // - https://github.com/buefy/buefy/issues/1048
    return (value === 0) ? 'muted' : '';
  }

  playerLink(playerId: string) {
    return playerStatsLink(playerId);
  }

}
