












































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Carousel, Slide} from '@noim/vue-carousel';
import {GamePicture, PictureSize} from '@/model/site/picture.model';
import {GameContentType} from '@/model/site/components.model';
import CarouselMixin from '@/mixins/carousel.mixin';
import CoolLightBox from 'vue-cool-lightbox';

@Component({
  mixins: [CarouselMixin],
  components: {Carousel, Slide, CoolLightBox},
})
export default class GamePictures extends Vue {
  @Prop(Array) pictures!: GamePicture[];
  @Prop({default: PictureSize.RESIZE}) size!: PictureSize;
  @Prop({default: 0}) maxPictures!: number;

  index = null;

  get id() {
    return GameContentType.BILDER;
  }

  get picturesMax(): GamePicture[] {
    if (!this.pictures || !this.pictures.filter) {
      return [];
    }
    // do not allow portrait pictures (e.g. 181221rap)
    let pictures = this.pictures
      .filter((picture: GamePicture) => (picture.height <= picture.width))
      .map((picture: GamePicture) => {
        return {
          ...picture,
          src: picture.resizeUrl,
          // thumb: picture.thumbnailUrl, -> no necessary, as images are loaded already eitherway
        };
      });
    if (this.maxPictures) {
      pictures = pictures.slice(0, this.maxPictures);
    }
    return pictures;
  }

  imageUrl(picture: GamePicture) {
    switch (this.size) {
      case PictureSize.FULL:
        return picture.fullUrl;
      case PictureSize.RESIZE:
        return picture.resizeUrl;
      case PictureSize.THUMBNAIL:
        return picture.thumbnailUrl;
      default:
        Vue.$log.warn(`Illegal size ${this.size}, returning ${PictureSize.RESIZE}`);
        return picture.resizeUrl;
    }
  }

}
