import {gamestats} from '@/services/columns/gamestats.columns';

export const teamstats = {
	teamId: {
		type: 'team',
		unsortable: true
	},
	gp: {
		headerName: 'SP',
		headerTooltip: 'Anzahl Spiele',
		unsortable: true
	},
	g: {
		headerName: 'T',
		type: 'number',
	},
	gopp: gamestats.gopp,
	gteam: gamestats.gteam,
	ppQuote: gamestats.ppQuote,
	ppGoalsFor: gamestats.ppGoalsFor,
	ppGoalsPct: gamestats.ppGoalsPct,
	ppGoalsAgainst: gamestats.ppGoalsAgainst,
	ppTime: gamestats.ppTime,
	ppGoalTime: gamestats.ppGoalTime,
	ppOpp: gamestats.ppOpp,
	shGoalsFor: gamestats.shGoalsFor,
	shGoalsAgainst: gamestats.shGoalsAgainst,
	shGoalsAgainstPct: gamestats.shGoalsAgainstPct,
	shGoalTime: gamestats.shGoalTime,
	shTime: gamestats.shTime,
	shOpp: gamestats.shOpp,
	shQuote: gamestats.shQuote,
	ps: gamestats.ps,
	psOpp: gamestats.psOpp,
	psMax: {
		headerName: 'Pn+',
		headerTooltip: 'Maximum verursachte Penalties in einem Spiel',
		type: 'number'
	},
	min2Max: {
		headerName: '2\'+',
		headerTooltip: 'Maximum Zwei-Minuten-Strafen in einem Spiel',
		type: 'number'
	},
	min10Max: {
		headerName: '10\'+',
		headerTooltip: 'Maximum Zehn-Mnuten-Strafen in einem Spiel',
		type: 'number'
	},
	discMax: {
		headerName: 'DS+',
		headerTooltip: 'Maximum Disziplinar-Strafen (Match-/Spieldauer) in einem Spiel',
		type: 'number'
	},
	min2Min: {
		headerName: '2\'-', headerTooltip: 'Minimum Zwei-Minuten-Strafen in einem Spiel',
		type: 'number',
	},
	min2: {
		headerName: '2\'', headerTooltip: 'Anzahl Zwei-Minuten-Strafen',
		type: 'number',
	},
	min2Opp: {
		headerName: '[2\']', headerTooltip: 'Anzahl Zwei-Minuten-Strafen des Gegners',
		type: 'number',
	},
	min2Gp: {
		headerName: '2\'/Sp', headerTooltip: 'Zwei-Minuten-Strafen pro Spiel',
		type: 'decimal'
	},
	min2GpOpp: {
		headerName: '[2\'/Sp]', headerTooltip: 'Zwei-Minuten-Strafen pro Spiel des Gegners',
		type: 'decimal'
	},
	min5: {
		headerName: '5\'', headerTooltip: 'Anzahl 5-Minuten-Strafen',
		type: 'number',
	},
	min10: {
		headerName: '10\'', headerTooltip: 'Anzahl 10-Minuten-Strafen',
		type: 'number',
	},
	min20: {
		headerName: 'SD', headerTooltip: 'Anzahl Spieldauerdisziplinar-Strafen',
		type: 'number',
	},
	min25: {
		headerName: 'MS', headerTooltip: 'Anzahl Match-Strafen',
		type: 'number',
	},
	disc: {
		headerName: 'DS', headerTooltip: 'Anzahl Spieldauerdisziplinar- und Matchstrafen',
		type: 'number',
	},
	pim: {
		headerName: 'SM', headerTooltip: 'Total Strafminuten',
		type: 'number',
	},
	min5Opp: {
		headerName: '[5\']', headerTooltip: 'Anzahl 5-Minuten-Strafen des Gegners',
		type: 'number',
	},
	min10Opp: {
		headerName: '[10\']', headerTooltip: 'Anzahl 10-Minuten-Strafen des Gegners',
		type: 'number',
	},
	min20Opp: {
		headerName: '[SD]', headerTooltip: 'Anzahl Spieldauerdisziplinar-Strafen des Gegners',
		type: 'number',
	},
	min25Opp: {
		headerName: '[MS]', headerTooltip: 'Anzahl Match-Strafen des Gegners',
		type: 'number',
	},
	discOpp: {
		headerName: '[DS]', headerTooltip: 'Anzahl Spieldauerdisziplinar- und Matchstrafen des Gegners',
		type: 'number',
	},
	pimH: {
		headerName: 'SM', headerTooltip: 'Total Strafminuten (Heim)',
		type: 'number',
	},
	pimA: {
		headerName: 'SM', headerTooltip: 'Total Strafminuten (Auwärts)',
		type: 'number',
	},
	pimMax: {
		headerName: 'SM+', headerTooltip: 'Maximum Strafminuten in einem Spiel',
		type: 'number',
	},
	pimMin: {
		headerName: 'SM-', headerTooltip: 'Minimum Strafminuten in einem Spiel',
		type: 'number',
	},
	pimOpp: {
		headerName: '[SM]', headerTooltip: 'Total Strafminuten des Gegners',
		type: 'number',
	},
	pimGpOpp: {
		headerName: '[SM/Sp]', headerTooltip: 'Total Strafminuten des Gegners',
		type: 'decimal'
	},
	pimGp: {
		headerName: 'SM/Sp', headerTooltip: 'Strafminuten pro Spiel',
		type: 'decimal',
	},
	attendanceSum: {
		headerName: 'Total',
		type: 'numberBig',
	},
	attendanceAvg: {
		headerName: 'Durchschnitt Ø',
		type: 'decimal0'
	},
	attendanceDev: {
		headerName: 'StdAbw',
		headerTooltip: 'Standard-Abweichung (Mass für Regelmässigkeit)',
		type: 'decimal0'
	},
	attendanceMax: {
		headerName: 'Rekordkulisse',
		type: 'number',
	},
	attendanceMin: {
		headerName: 'Minuskulisse',
		type: 'number',
	},
	avgLeading: gamestats.avgLeading,
	eqPct: gamestats.eqPct,
	lead1_2Pct: gamestats.lead1_2Pct,
	lead3_10Pct: gamestats.lead3_10Pct,
	leadPct: gamestats.leadPct,
	lead1Pct: gamestats.lead1Pct,
	lead2Pct: gamestats.lead2Pct,
	lead3Pct: gamestats.lead3Pct,
	lead4Pct: gamestats.lead4Pct,
	lead5Pct: gamestats.lead5Pct,
	lead6Pct: gamestats.lead6Pct,
	lead7Pct: gamestats.lead7Pct,
	lead8Pct: gamestats.lead8Pct,
	lead9Pct: gamestats.lead9Pct,
	lead10Pct: gamestats.lead10Pct,
	lag1_2Pct: gamestats.lag1_2Pct,
	lag3_10Pct: gamestats.lag3_10Pct,
	lagPct: gamestats.lagPct,
	lag1Pct: gamestats.lag1Pct,
	lag2Pct: gamestats.lag2Pct,
	lag3Pct: gamestats.lag3Pct,
	lag4Pct: gamestats.lag4Pct,
	lag5Pct: gamestats.lag5Pct,
	lag6Pct: gamestats.lag6Pct,
	lag7Pct: gamestats.lag7Pct,
	lag8Pct: gamestats.lag8Pct,
	lag9Pct: gamestats.lag9Pct,
	lag10Pct: gamestats.lag10Pct,
	diffMi6: {
		headerName: '-6..', headerTooltip: 'Niederlagen mit mehr als 6 Toren Differenz',
		type: 'number',
	},
	diffMi5: {
		headerName: '-5', headerTooltip: 'Niederlagen mit 5 Toren Differenz',
		type: 'number',
	},
	diffMi4: {
		headerName: '-4', headerTooltip: 'Niederlagen mit 4 Toren Differenz',
		type: 'number',
	},
	diffMi3: {
		headerName: '-3', headerTooltip: 'Niederlagen mit 3 Toren Differenz',
		type: 'number',
	},
	diffMi2: {
		headerName: '-2', headerTooltip: 'Niederlagen mit 2 Toren Differenz',
		type: 'number',
	},
	diffMi1: {
		headerName: '-1', headerTooltip: 'Niederlagen mit 1 Tor Differenz',
		type: 'number',
	},
	diffPl6: {
		headerName: '+6..', headerTooltip: 'Siege mit mehr als 6 Toren Differenz',
		type: 'number',
	},
	diffPl5: {
		headerName: '+5', headerTooltip: 'Siege mit 5 Toren Differenz',
		type: 'number',
	},
	diffPl4: {
		headerName: '+4', headerTooltip: 'Siege mit 4 Toren Differenz',
		type: 'number',
	},
	diffPl3: {
		headerName: '+3', headerTooltip: 'Siege mit 3 Toren Differenz',
		type: 'number',
	},
	diffPl2: {
		headerName: '+2', headerTooltip: 'Siege mit 2 Toren Differenz',
		type: 'number',
	},
	diffPl1: {
		headerName: '+1', headerTooltip: 'Siege mit 1 Tor Differenz',
		type: 'number',
	},
	diff_0: {
		headerName: 'U', headerTooltip: 'Unentschieden',
		type: 'number',
	},
	n: {
		headerName: 'N', headerTooltip: 'Niederlagen',
		type: 'number',
	},
	s: {
		headerName: 'S', headerTooltip: 'Siege',
		type: 'number',
	},
	otPenN: {
		headerName: 'N-', headerTooltip: 'Niederlagen nach Verlängerung oder Penaltyschiessen',
		type: 'number',
	},
	otPenS: {
		headerName: 'S+', headerTooltip: 'Siege nach Verlängerung oder Penaltyschiessen',
		type: 'number',
	},
	sog: gamestats.sog,
	sogOpp: gamestats.sogOpp,
	sogPct: gamestats.sogPct,
	sogPctOpp: gamestats.sogPctOpp,
	plmi: {
		headerName: '+/-',
		type: 'number',
		headerTooltip: 'Summierte Plus-Minus-Bilanz',
	},
	plmiOpp: {
		headerName: '[+/-]',
		type: 'number',
		headerTooltip: 'Summierte Plus-Minus-Bilanz des Gegners',
	},
	en: gamestats.en,
	enOpp: gamestats.enOpp,
	wg: gamestats.wg,
	wgOpp: gamestats.wgOpp,
};
