import {COLUMN_FORMATTERS, opponent} from '@/services/columns/columns.base';
import {playerstats} from '@/services/columns/playerstats.columns';

const fields: any = {
	gameDate: {
		type: 'dateShort'
	},
	gameId: {
		type: 'game'
	},
	homeTeamId: {
		headerName: 'Heim',
		type: 'team',
		unsortable: true
	},
	ha: playerstats.ha,
	gameTypeId: {
		type: 'gameType',
		unsortable: true
	},
	awayTeamId: {
		headerName: 'Gast',
		type: 'team',
		unsortable: true
	},
	teamId: {
		headerName: 'Team',
		type: 'team',
		unsortable: true
	},
	oppTeamId: {
		headerName: 'Gegner',
		type: 'team',
		unsortable: true
	},
	attendance: {
		headerName: 'Zuschauer',
		type: 'number',
		suppressSizeToFit: true,
		width: 80,
	},
	attendanceMax: {
		headerName: 'Zuschauer',
		type: 'number',
		suppressSizeToFit: true,
		width: 80,
	},
	attendanceMin: {
		headerName: 'Zuschauer',
		type: 'number',
		suppressSizeToFit: true,
		width: 80,
	},
	gopp: {
		headerName: 'GT',
		headerTooltip: 'Gegentore',
		type: 'number',
	},
	gteam: {
		headerName: 'PT',
		headerTooltip: 'Plustore',
		type: 'number',
	},
	sun: {
		headerName: 'SN', headerTooltip: 'Sieg / Niederlage (Unentschieden)',
		valueFormatter: COLUMN_FORMATTERS.formatSUN,
		unsortable: true
	},
	ot: {
		headerName: 'OT',
		headerTooltip: 'Verlängerung/Penaltyschiessen',
		valueFormatter: COLUMN_FORMATTERS.formatOT,
		unsortable: true
	},
	result: {
		headerName: 'Resultat',
		valueFormatter: COLUMN_FORMATTERS.formatResult,
		unsortable: true,
	},
	goals: {
		headerName: 'Tore',
		type: 'number'
	},
	goalsMax: {
		headerName: 'Tore',
		type: 'number'
	},
	goalsMin: {
		headerName: 'Tore',
		type: 'number'
	},
	sog: {
		headerName: 'SG',
		headerNameLong: 'Schüsse aufs Tor',
		headerTooltip: 'Schüsse aufs Tor',
		type: 'number'
	},
	sogPct: {
		headerName: 'SG%',
		type: 'percent1',
		headerTooltip: 'Schusseffizienz (eigene) Schüsse aufs (gegnerische) Tor',
	},
	sogPctOpp: {
		headerName: '[SG%]',
		type: 'percent1',
		headerTooltip: 'Schusseffizienz (Gegner) aufs (eigene) Tor',
	},
	ps: playerstats.ps,
	min2: playerstats.min2,
	min5: playerstats.min5,
	min10: playerstats.min10,
	min20: playerstats.min20,
	min25: playerstats.min25,
	disc: playerstats.disc,
	pim: {
		headerName: 'SM',
		headerNameLong: 'Strafminuten',
		headerTooltip: 'Total Strafminuten',
		type: 'number'
	},
	pimTotal: {
		headerName: 'SM-Total',
		headerTooltip: 'Total Strafminuten',
		type: 'number'
	},
	ppQuote: {
		headerName: 'PP%',
		headerTooltip: 'Powerplay-Quote',
		type: 'percent0',
	},
	ppTime: {
		headerName: 'PPT',
		headerNameLong: 'Powerplayzeit',
		headerTooltip: 'Total Zeit in Überzahl',
		type: 'time',
	},
	ppGoalsFor: {
		headerName: 'PP+',
		headerNameLong: 'Powerplay-Tore',
		headerTooltip: 'Erzielte Powerplay-Tore',
		type: 'number'
	},
	ppGoalsPct: {
		headerName: 'PP+%',
		type: 'percent0',
		headerTooltip: 'Anteil erzielter Powerplay-Tore an allen (Plus-)Toren',
	},
	ppGoalTime: {
		headerName: 'Min/PP+',
		type: 'time',
		headerTooltip: 'Zeit pro erzieltes Powerplay-Tor'
	},
	ppOpp: {
		headerName: 'PP#',
		headerTooltip: 'Anzahl Powerplay-Gelegenheiten'
	},
	ppGoalsAgainst: {
		headerName: 'SH-',
		headerTooltip: 'Kassierte Shorthander (eigene Überzahl)',
		type: 'number'
	},
	shQuote: {
		headerName: 'BP%',
		headerTooltip: 'Boxplay-Quote',
		type: 'percent0',
	},
	shOpp: {
		headerName: 'SH#',
		headerTooltip: 'Anzahl Unterzahl-Gelegenheiten',
		type: 'number',
	},
	shTime: {
		headerName: 'SHZ',
		headerTooltip: 'Zeit in Unterzahl',
		type: 'time',
	},
	shGoalsAgainst: {
		headerName: 'PP-',
		headerTooltip: 'Erhaltene Powerplay-Tore (eigene Unterzahl)',
		type: 'number'
	},
	shGoalsAgainstPct: {
		headerName: 'SH%',
		type: 'percent0',
		headerTooltip: 'Anteil erhaltene Powerplay-Tore (eigene Unterzahl) an allen Gegentoren'
	},
	shGoalsFor: {
		headerName: 'SH+',
		headerNameLong: 'Shorthander',
		headerTooltip: 'Erzielte Shorthander',
		type: 'number'
	},
	shGoalTime: {
		headerName: 'Min/PP-',
		type: 'time',
		headerTooltip: 'Zeit pro kassiertes Powerplay-Tor',
	},
	plmi: {
		headerName: '+/-',
		headerNameLong: '+/- Bilanz',
		type: 'number',
		headerTooltip: 'Summierte Plus-Minus-Bilanz des ganzen Teams',
	},
	avgLeading: {
		headerName: 'F/R [Ø]',
		headerTooltip: 'Durchschnittliche Führung/Rückstand',
		type: 'decimal2'
	},
	eqPct: {
		headerName: 'GS', headerTooltip: 'Zeit in Gleichstand',
		type: 'percent0',
	},
	lead1_2Pct: {
		headerName: 'F1/2', headerTooltip: 'Zeit in Führung mit 1 oder 2 Toren',
		type: 'percent0',
	},
	lead3_10Pct: {
		headerName: 'F3…', headerTooltip: 'Zeit in Führung mit 3 oder mehr Toren',
		type: 'percent0',
	},
	leadPct: {
		headerName: 'F', headerTooltip: 'Zeit in Führung',
		type: 'percent0',
	},
	lead1Pct: {
		headerName: 'F1', headerTooltip: 'Zeit in Führung mit 1 Tor',
		type: 'percent0',
	},
	lead2Pct: {
		headerName: 'F2', headerTooltip: 'Zeit in Führung mit 2 Toren',
		type: 'percent0',
	},
	lead3Pct: {
		headerName: 'F3', headerTooltip: 'Zeit in Führung mit 3 Toren',
		type: 'percent0',
	},
	lead4Pct: {
		headerName: 'F4', headerTooltip: 'Zeit in Führung mit 4 Toren',
		type: 'percent0',
	},
	lead5Pct: {
		headerName: 'F5', headerTooltip: 'Zeit in Führung mit 5 Toren',
		type: 'percent0',
	},
	lead6Pct: {
		headerName: 'F6', headerTooltip: 'Zeit in Führung mit 6 Toren',
		type: 'percent0',
	},
	lead7Pct: {
		headerName: 'F7', headerTooltip: 'Zeit in Führung mit 7 Toren',
		type: 'percent0',
	},
	lead8Pct: {
		headerName: 'F8', headerTooltip: 'Zeit in Führung mit 8 Toren',
		type: 'percent0',
	},
	lead9Pct: {
		headerName: 'F9', headerTooltip: 'Zeit in Führung mit 9 Toren',
		type: 'percent0',
	},
	lead10Pct: {
		headerName: 'F10…', headerTooltip: 'Zeit in Führung mit 10 oder mehr Toren',
		type: 'percent0',
	},
	lag1_2Pct: {
		headerName: 'R1/2', headerTooltip: 'Zeit in Rückstand mit 1 oder 2 Toren',
		type: 'percent0',
	},
	lag3_10Pct: {
		headerName: 'R3…', headerTooltip: 'Zeit in Rückstand mit 3 oder mehr Toren',
		type: 'percent0',
	},
	lagPct: {
		headerName: 'R', headerTooltip: 'Zeit in Rückstand',
		type: 'percent0',
	},
	lag1Pct: {
		headerName: 'R1', headerTooltip: 'Zeit in Rückstand mit 1 Tor',
		type: 'percent0',
	},
	lag2Pct: {
		headerName: 'R2', headerTooltip: 'Zeit in Rückstand mit 2 Toren',
		type: 'percent0',
	},
	lag3Pct: {
		headerName: 'R3', headerTooltip: 'Zeit in Rückstand mit 3 Toren',
		type: 'percent0',
	},
	lag4Pct: {
		headerName: 'R4', headerTooltip: 'Zeit in Rückstand mit 4 Toren',
		type: 'percent0',
	},
	lag5Pct: {
		headerName: 'R5', headerTooltip: 'Zeit in Rückstand mit 5 Toren',
		type: 'percent0',
	},
	lag6Pct: {
		headerName: 'R6', headerTooltip: 'Zeit in Rückstand mit 6 Toren',
		type: 'percent0',
	},
	lag7Pct: {
		headerName: 'R7', headerTooltip: 'Zeit in Rückstand mit 7 Toren',
		type: 'percent0',
	},
	lag8Pct: {
		headerName: 'R8', headerTooltip: 'Zeit in Rückstand mit 8 Toren',
		type: 'percent0',
	},
	lag9Pct: {
		headerName: 'R9', headerTooltip: 'Zeit in Rückstand mit 9 Toren',
		type: 'percent0',
	},
	lag10Pct: {
		headerName: 'R10…', headerTooltip: 'Zeit in Rückstand mit 10 oder mehr Toren',
		type: 'percent0',
	},
	en: {
		headerName: 'EN',
		heaerNameLong: 'Empty-Netter',
		type: 'number',
		headerTooltip: 'Empty-Netter: Erzielte Tore ohne gegnerischen Torhüter',
	},
	enOpp: {
		headerName: '[EN]',
		type: 'number',
		headerTooltip: 'Empty-Netter: Gegentore ohne eigenen Torhüter',
	},
	wg: {
		headerName: 'OT',
		headerNameLong: 'Tore ohne Torhüter',
		type: 'number',
		headerTooltip: 'Erzielte Tore ohne eigenen Torhüter',
	},
	wgOpp: {
		headerName: '[OT]',
		type: 'number',
		headerTooltip: 'Gegentore ohne gegnerischen Torhüter',
	},
};


export const gamestats = {
	...fields,
	plmiOpp: opponent(fields.plmi),
	sogOpp: opponent(fields.sog, 'vom Gegner'),
	pimOpp: opponent(fields.pim),
	pimTotalOpp: opponent(fields.pimTotal),
	psOpp: opponent(fields.ps),
	min2Opp: opponent(fields.min2),
	min5Opp: opponent(fields.min5),
	min10Opp: opponent(fields.min10),
	min20Opp: opponent(fields.min20),
	min25Opp: opponent(fields.min25),
	discOpp: opponent(fields.disc),
	shotsH1: {
		headerNameLong: 'Schüsse Drittel 1',
	},
	shotsH2: {
		headerNameLong: 'Schüsse Drittel 2',
	},
	shotsH3: {
		headerNameLong: 'Schüsse Drittel 3',
	},
	shotsHot: {
		headerNameLong: 'Schüsse OT',
	},
	shotsHt: {
		headerNameLong: 'Total Schüsse',
	},
};
