import {CELL_CLASSES, COLUMN_FORMATTERS, EMPTY} from '@/services/columns/columns.base';
import {playerstats} from '@/services/columns/playerstats.columns';

export const goaliestats = {
	playerId: {
		type: 'player'
	},
	nationalityId_1: {
		headerName: EMPTY,
		type: 'country'
	},
	nationalityId_2: {
		headerName: EMPTY,
		type: 'country'
	},
	gameDate: {
		type: 'dateShort'
	},
	gameId: {
		type: 'game',
	},
	homeTeamId: {
		type: 'team',
		headerName: 'Heim'
	},
	awayTeamId: {
		type: 'team',
		headerName: 'Gast'
	},
	oppTeamId: {
		type: 'team',
		headerName: 'Opp.',
		headerTooltip: 'Gegner',
		unsortable: true
	},
	ha: playerstats.ha,
	seasonId: {
		type: 'season',
		unsortable: true
	},
	leagueId: {
		type: 'league',
		unsortable: true
	},
	gameTypeId: {
		type: 'gameType',
		unsortable: true
	},
	teamId: {
		type: 'team',
		headerName: 'Team',
		unsortable: true
	},
	curTeamId: {
		type: 'team',
		headerName: 'Akt. Team',
		unsortable: true
	},
	gp: {
		headerName: 'Sp',
		headerTooltip: 'Total Spiele als Ersatztorhüter oder mit (Teil-)Einsatz',
		type: 'number',
	},
	assignments: {
		headerName: 'TE',
		headerTooltip: 'Total Einsätze',
		type: 'number',
	},
	completeGame: {
		headerName: 'GS', headerTooltip: 'Ganze Spiele durchgespielt',
		type: 'numberBoolean'
	},
	spare: {
		headerName: 'Ers', headerTooltip: 'Spiele als Ersatztorhüter',
		type: 'numberBoolean'
	},
	gkOut: {
		headerName: 'SB', headerTooltip: 'Spiel -> Bank: Anzahl Spiele ausgewechselt',
		type: 'numberBoolean'
	},
	gkIn: {
		headerName: 'BS', headerTooltip: 'Bank -> Spiel: Anzahl Spiele eingewechselt',
		type: 'numberBoolean'
	},
	toi: {
		headerName: 'TOI', headerTooltip: 'Time-on-Ice: Total Eiszeit in Minuten',
		type: 'time',
		suppressSizeToFit: true,
		valueFormatter: COLUMN_FORMATTERS.timeOnIce,
		cellClass: CELL_CLASSES.muteZero,
	},
	en: {
		headerName: 'EN', headerTooltip: 'Gegentore bei 6 gegen 5 Feldspielern',
		type: 'number'
	},
	ga: {
		headerName: 'GT', headerTooltip: 'Anzahl Gegentore',
		type: 'number',
	},
	toiGa: {
		headerName: 'Min/GT', headerTooltip: 'Minuten pro Gegentor',
		type: 'time'
	},
	ga_60: {
		headerName: 'GT/60', headerTooltip: 'Gegentore auf 60 Minuten Eiszeit (TOI) gerechnet',
		type: 'decimal',
	},
	svPct: {
		type: 'percent1',
		headerName: 'SV%', headerTooltip: 'Quote blockierter Schüsse',
	},
	sb: {
		headerName: 'SV', headerTooltip: 'Geblockte Schüsse (Saves)',
		type: 'number'
	},
	st: {
		headerName: 'ST', headerTooltip: 'Total Schüsse',
		type: 'number'
	},
	st_60: {
		headerName: 'ST/60', headerTooltip: 'Total Schüsse auf 60 Minuten Eiszeit (TOI) gerechnet',
		type: 'decimal',
	},
	so: {
		headerName: 'SO', headerTooltip: 'Anzahl Shutouts',
		type: 'numberBoolean'
	},
	tie: {
		headerName: 'U', headerTooltip: 'Unentschiedene Spiele',
		type: 'numberBoolean',
	},
	won: {
		headerName: 'S', headerTooltip: 'Gewonnene Spiele',
		type: 'numberBoolean',
	},
	loss: {
		headerName: 'N', headerTooltip: 'Verlorene Spiele',
		type: 'numberBoolean',
	},
	wonPct: {
		headerName: 'S%', headerTooltip: 'Anteil gewonnene Spiele (bzgl. Anzahl Einsätze)',
		type: 'percent0',
	},
	tiePct: {
		headerName: 'U%', headerTooltip: 'Anteil unentschiedene Spiele (bzgl. Anzahl Einsätze)',
		type: 'percent0',
	},
	lossPct: {
		headerName: 'N%', headerTooltip: 'Anteil verlorene Spiele (bzgl. Anzahl Einsätze)',
		type: 'percent0',
	},
	pim: {
		headerName: 'PIM', headerTooltip: 'Strafminuten',
		type: 'number'
	},
	a: {
		headerName: 'A', headerTooltip: 'Assists',
		type: 'number'
	},

};
