export enum PictureSize {
	THUMBNAIL = 'thumbnail',
	FULL = 'full',
	RESIZE = 'resize',
}

export interface GamePicture {
	id: string;
	fullUrl: string;
	resizeUrl?: string;
	thumbnailUrl?: string;
	description?: string;
	width: number;
	height: number;
}
