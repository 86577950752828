





















































































import {Component, Prop, Vue} from 'vue-property-decorator';
  import StatsTableMixin from '@/mixins/stats-table.mixin';
  import {GameContentType} from '@/model/site/components.model';
  import {hasKeys} from '@/utils/common.util';
  import {playerStatsLink} from '@/router';

  @Component({
    components: {},
    mixins: [StatsTableMixin]
  })
  export default class GameAssignmentStats extends Vue {

    @Prop(Array) data!: any[];

    get id() {
      return GameContentType.STATS;
    }

    get safeData() {
      this.data.forEach(d => {
        if (!d.stats) {
          d.stats = {};
        }
      });
      return this.data.filter(d => {
        return hasKeys(d.stats);
      });
    }

    muted(value: any) {
      // note that using `v-html ... muteNulls(.)` will cause column headers to dissapear on update
      // - https://github.com/buefy/buefy/issues/1048
      return (value === 0) ? 'muted' : '';
    }

    playerLink(playerId: string) {
      return playerStatsLink(playerId);
    }

  }
