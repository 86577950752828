import {EMPTY} from '@/services/columns/columns.base';

export const playerstats = {
	playerId: {
		type: 'player'
	},
	ha: {
		type: 'homeAway',
		headerTooltip: 'Heimspiel | Auswärtsspiel',
		unsortable: true,
	},
	nationalityId_1: {
		headerName: EMPTY,
		type: 'country'
	},
	nationalityId_2: {
		headerName: EMPTY,
		type: 'country'
	},
	type: {
		headerName: 'Pos',
		headerTooltip: 'Position auf dem Matchblatt',
		type: 'position',
	},
	gameDate: {
		type: 'dateShort'
	},
	gameId: {
		type: 'game'
	},
	homeTeamId: {
		type: 'team',
		headerName: 'Heim'
	},
	awayTeamId: {
		type: 'team',
		headerName: 'Gast'
	},
	oppTeamId: {
		type: 'team',
		headerName: 'Opp.',
		headerTooltip: 'Gegner',
		unsortable: true
	},
	position: {
		type: 'position',
		unsortable: true,
	},
	seasonId: {
		type: 'season'
	},
	leagueId: {
		type: 'league'
	},
	gameTypeId: {
		type: 'gameType'
	},
	teamId: {
		type: 'team',
		headerName: 'Team',
		unsortable: true
	},
	curTeamId: {
		type: 'team',
		headerName: 'Akt. Team',
		unsortable: true
	},
	gp: {
		headerName: 'Sp',
		type: 'number'
	},
	g: {
		headerName: 'T',
		type: 'number'
	},
	a: {
		headerName: 'A',
		type: 'number'
	},
	a1: {
		headerName: 'A1',
		type: 'number'
	},
	a2: {
		headerName: 'A2',
		type: 'number'
	},
	p: {
		headerName: 'P',
		type: 'number'
	},
	plmi: {
		headerName: '+/-',
		type: 'plusMinus'
	},
	sog: {
		headerName: 'S',
		type: 'number'
	},
	gwg: {
		headerName: 'GWG',
		type: 'number'
	},
	gtg: {
		headerName: 'GTG',
		type: 'number',
	},
	ppg: {
		headerName: 'PPT',
		type: 'number',
		headerTooltip: 'Tore in Überzahl'
	},
	shg: {
		headerName: 'SHT',
		type: 'number',
		headerTooltip: 'Tore in Unterzahl'
	},
	en: {
		headerName: 'EN', headerTooltip: 'Empty-Netter: Tore ohne gegnerischen Torhüter',
		type: 'number'
	},
	wg: {
		headerName: 'OT', headerTooltip: 'Without Goalkeeper: Tore ohne eigenen Torhüter',
		type: 'number'
	},
	// FIXME format 0 / 12 => 0%
	sogPct: {
		headerName: 'S%',
		type: 'percent0',
	},
	pim: {
		headerName: 'SM',
		headerNameLong: 'Strafminuten',
		headerTooltip: 'Total Strafminuten',
		type: 'number'
	},
	pimGp: {
		headerName: 'MSp', headerTooltip: 'Strafminuten pro Spiel',
		type: 'decimal'
	},
	ps: {
		headerName: 'Pn',
		headerNameLong: 'Penalties',
		headerTooltip: 'Verursachte Penalties',
		type: 'number'
	},
	min2: {
		headerName: '2\'',
		headerNameLong: '2-Min.-Strafen',
		headerTooltip: 'Anzahl Zwei-Minuten-Strafen',
		type: 'number'
	},
	min5: {
		headerName: '5\'',
		headerNameLong: '5-Min.-Strafen',
		headerTooltip: 'Anzahl 5-Minuten-Strafen',
		type: 'number'
	},
	min10: {
		headerName: '10\'',
		headerNameLong: '10-Min.-Strafen',
		headerTooltip: 'Anzahl 10-Minuten-Strafen',
		type: 'number'
	},
	min20: {
		headerName: 'SD',
		headerNameLong: 'Spieldauerdisziplinar',
		headerTooltip: 'Anzahl Spieldauerdisziplinar-Strafen',
		type: 'number'
	},
	min25: {
		headerName: 'MS',
		headerNameLong: 'Match-Strafen',
		headerTooltip: 'Anzahl Match-Strafen',
		type: 'number'
	},
	disc: {
		headerName: 'DS',
		headerNameLong: 'SD-/Match-Strafen',
		headerTooltip: 'Anzahl Spieldauerdisziplinar- und Matchstrafen',
		type: 'number'
	},
	min2Gp: {
		headerName: '2\'/Sp', headerTooltip: 'Zwei-Minuten-Strafen pro Spiel',
		type: 'decimal'
	},
	ggp: {
		headerName: 'T/Sp', headerTooltip: 'Tore pro Spiel',
		type: 'decimal'
	},
	agp: {
		headerName: 'A/Sp', headerTooltip: 'Punkte pro Spiel',
		type: 'decimal'
	},
	pgp: {
		headerName: 'P/Sp', headerTooltip: 'Punkte pro Spiel',
		type: 'decimal'
	}
};

