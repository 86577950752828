










import {Component, Vue} from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class GameHeaderResult extends Vue {
  @Prop({required: true}) goals!: string;
  @Prop({required: true}) periods!: string;

}
