




























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameInfo} from '@/model/game/game.model';
import {Getter} from 'vuex-class';
import GameLinks from '@/components/event/GameLinks.vue';
import {GameMode} from '@/model/site/context.model';
import DateFilters from '@/mixins/date-filters.mixin';
import StringFilters from '@/mixins/string-filters.mixin';
import Loading from 'vue-loading-overlay';
import {CURRENT_GAME_ID} from '@/store/data/getters';

@Component({
  components: {GameLinks, Loading},
  mixins: [DateFilters, StringFilters],
})
export default class GameList extends Vue {
  @Prop(String) mode!: GameMode;
  @Prop(Array) games!: GameInfo[];
  @Prop(String) teamId?: string;
  @Prop({required: true}) waitingId!: string;

  @Getter(CURRENT_GAME_ID) currentGameId?: string;

  isSameGame(gameId: string): boolean {
    return this.currentGameId === gameId;
  }

  showTeam(game: GameInfo, teamId: string) {
    if (this.mode === GameMode.SAME_TEAM) {
      return this.teamId !== teamId;
    }
    return true;
  }

  winLossStyle(game: GameInfo): string {
    if (game.homeTeamId === this.teamId) {
      return game.homeGoals > game.awayGoals ? 'hf-is-positive' : 'hf-is-negative';
    } else if (game.awayTeamId === this.teamId) {
      return game.homeGoals < game.awayGoals ? 'hf-is-positive' : 'hf-is-negative';
    } else {
      return '';
    }
  }
}
