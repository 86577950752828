import Vue from 'vue';

import {teamstats} from '@/services/columns/teamstats.columns';
import {goaliestats} from '@/services/columns/goaliestats.columns';
import {playerstats} from '@/services/columns/playerstats.columns';
import {gamestats} from '@/services/columns/gamestats.columns';

/**
 * Column definitions for both, ag-grid and buefy-table.
 */
const COLUMNS: any = {
	goaliestats,
	playerstats,
	gamestats,
	teamstats,

	definitions: function (resource: string, fieldIds: string[]): any {
		if (!this[resource]) {
			Vue.$log.error(`No column definitions for '${resource}' (fieldIds=${fieldIds})`);
			return {};
		}
		return this[resource];
	}

};

export default COLUMNS;
